<template lang="pug">
  div
    div.ml-8
      VatSectionTitle(
        :title="title"
      )
    div.py-3(v-for="(item, index) in list", :key="item.id", :data-testid="`${title}-${item.id}`")
        VatReviewSection(
          is-sub-section
          :title="item.title"
          :address="item.address"
          :vatDetails="item.vatDetails"
          :status="item.approvalStatus"
          @on-rejection-reason-change="$emit('on-rejection-reason-change', {value: $event, id: item.id})"
          @on-status-change="$emit('on-status-change', {value: $event, id: item.id})"
          :rejection-reason="item.rejectionReason"
          :selected-status="item.selectedStatus"
          :enable-review="enableReview"
          :compliance-status="complianceStatus"
          :lrd-details="item.lrdDetails"
        )
        v-divider(v-if="list.length -1 !== index")

</template>

<script lang="ts">
import Vue from 'vue';
import VatReviewSection from 'Merchant/common/components/vat-review-section/vat-review-section.vue';
import VatSectionTitle from 'Merchant/common/components/review-section-title/review-section-title.vue';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';
import { VatReviewListItemT } from 'Merchant/common/components/vat-review-section/types';

export default Vue.extend({
  components: { VatSectionTitle, VatReviewSection },
  props: {
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Array as () => Array<VatReviewListItemT>,
      required: true,
    },
    enableReview: {
      type: Boolean,
      required: true,
    },
    complianceStatus: {
      type: String as () => ApprovalStatusT,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped></style>
