<template lang="pug">
  v-app.root.px-10.py-6
    v-snackbar(v-model="showError" :timeout="5000" color="reject" bottom right)
      | {{errorMessage}}

    ReviewStatusLegend(title="Compliance data")
    div.d-flex.justify-center(v-if="isLoading")
      v-progress-circular(
        color="secondaryDark"
        indeterminate
      )
    div.px-2.py-4(v-else-if="!showError")
      div(
        v-if="isSectionRequired(sectionTypes.FIXED_ESTABLISHMENT_EU)"
        data-testid="fixed-establishment-eu"
        )
        div.pb-4(v-if="euFixedEstablishmentData")
          VatReviewSection(
            :title="titles.euFixedEstablishment"
            :address="euFixedEstablishmentData.address"
            :vat-details="euFixedEstablishmentData.vat"
            :status="euFixedEstablishmentData.approvalStatus"
            :show-address-subtext="true"
            :selected-status="selectedStatuses.euFixedEstablishment"
            :rejection-reason="providedRejectionReasons.euFixedEstablishment"
            @on-rejection-reason-change="onRejectionReasonChange('euFixedEstablishment', $event)"
            @on-status-change="onReviewStatusChange('euFixedEstablishment', $event)"
            :enable-review="isReviewEnabled"
            :hide-rejection-option="!euFixedEstablishmentData.hasFixedEstablishment"
            :compliance-status="complianceStatus"
          )
            div(v-if="!euFixedEstablishmentData.hasFixedEstablishment")
              div.text-body-1(v-if="isEuFixedEstablishmentApproved")
                | Fixed Establishment in EU does not exist
              v-banner(
                v-else
                icon="mdi-information-outline"
                icon-color="#315adc"
                outlined
                rounded
              ) Partner does not have a Fixed Establishment in EU. You may need to check and update this information in SAP as their Fixed Establishment status may have changed.


        NoDataSection(
          v-else
          :title="titles.euFixedEstablishment"
        )

      div(
        v-if="isSectionRequired(sectionTypes.OSS)"
        data-testid="oss"
        )
        div.pb-4(v-if="ossVatDetails")
          VatReviewSection(
            :title="titles.oss"
            :vat-details="ossVatDetails.vat"
            :status="ossVatDetails.approvalStatus"
            :show-address-subtext="true"
            :selected-status="selectedStatuses.oss"
            :rejection-reason="providedRejectionReasons.oss"
            @on-rejection-reason-change="onRejectionReasonChange('oss', $event)"
            @on-status-change="onReviewStatusChange('oss', $event)"
            :enable-review="isReviewEnabled"
            :hide-rejection-option="!ossVatDetails.hasOss"
            :compliance-status="complianceStatus"
            :vat-group-details="ossVatDetails.vatGroup"
          )
            div.text-body-1(v-if="!ossData.hasOss")
              | Partner does not have One Stop Shop details

        NoDataSection(
          v-else
          :title="titles.oss"
        )

      div(
        v-if="isSectionRequired(sectionTypes.FIXED_ESTABLISHMENT_UK)"
        data-testid="fixed-establishment-uk"
      )
        div.pb-4(v-if="ukFixedEstablishmentData")
          VatReviewSection(
            :title="titles.ukFixedEstablishment"
            :address="ukFixedEstablishmentData.address"
            :vat-details="ukFixedEstablishmentData.vat"
            :status="ukFixedEstablishmentData.approvalStatus"
            :show-address-subtext="true"
            :selected-status="selectedStatuses.ukFixedEstablishment"
            :rejection-reason="providedRejectionReasons.ukFixedEstablishment"
            @on-rejection-reason-change="onRejectionReasonChange('ukFixedEstablishment', $event)"
            @on-status-change="onReviewStatusChange('ukFixedEstablishment', $event)"
            :hide-rejection-option="!ukFixedEstablishmentData.hasFixedEstablishment"
            :enable-review="isReviewEnabled"
            :compliance-status="complianceStatus"
          )
            div(v-if="!ukFixedEstablishmentData.hasFixedEstablishment")
              div.text-body-1(v-if="isUkFixedEstablishmentApproved")
                | Fixed Establishment in UK does not exist
              v-banner(
                v-else
                icon="mdi-information-outline"
                icon-color="#315adc"
                outlined
                rounded
              ) Partner does not have a Fixed Establishment in UK. You may need to check and update this information in SAP as their Fixed Establishment status may have changed.

        NoDataSection(
          v-else
          :title="titles.ukFixedEstablishment"
        )

      div(
        v-if="isSectionRequired(sectionTypes.ZFS_VAT)"
        data-testid="zfs-vat"
      )
        div.pb-4(v-if="zfsVatList.length")
          VatReviewListSection(
            :title="titles.zfsVat"
            :list="zfsVatList"
            @on-rejection-reason-change="({value, id}) => onRejectionReasonChange('zfsVat', value, id)"
            @on-status-change="({value, id}) => onReviewStatusChange('zfsVat', value, id)"
            :enable-review="isReviewEnabled"
            :compliance-status="complianceStatus"
          )

        NoDataSection(
          v-else
          :title="titles.zfsVat"
        )

      div(
        v-if="isSectionRequired(sectionTypes.OUTBOUND_WAREHOUSES)"
        data-testid="outbound-warehouse"
      )
        div.pb-4(v-if="outboundWarehouseList.length")
          VatReviewListSection(
            :title="titles.outboundWarehouse"
            :list="outboundWarehouseList"
            @on-rejection-reason-change="({value, id}) => onRejectionReasonChange('outboundWarehouse', value, id)"
            @on-status-change="({value, id}) => onReviewStatusChange('outboundWarehouse', value, id)"
            :enable-review="isReviewEnabled"
            :compliance-status="complianceStatus"
          )

        NoDataSection(
          v-else
          :title="titles.outboundWarehouse"
        )

      div.text-center(v-if="showSubmitBtn")
        v-btn.white--text.text-none(
          :loading="isSubmitting"
          color="black"
          @click="handleSubmit"
          :disabled="isSubmitBtnDisabled"
        ) Confirm review of Compliance details

</template>

<script lang="ts">
import Vue from 'vue';
import {
  fetchFixedEstablishmentData,
  fetchOssData,
  fetchZfsVatData,
} from 'Api/endpoints/legal-entity/compliance.endpoint';
import { MerchantComplianceRequirementT } from 'Api/models/ComplianceRequirement';
import {
  FixedEstablishmentResponseT,
  FixedEstablishmentTypeT,
} from 'Api/models/FixedEstablishment';
import {
  fetchMerchantComplianceDetailsRequirements,
  fetchMerchantComplianceStatus,
  fetchOutboundWarehouseData,
} from 'Api/endpoints/merchant/merchant-compliance.endpoint';
import VatAddress from 'Merchant/common/components/vat-section/vat-address.vue';
import VatIdDetails from 'Merchant/common/components/vat-section/vat-id-details.vue';
import VatReviewSection from 'Merchant/common/components/vat-review-section/vat-review-section.vue';
import VatReviewListSection from 'Merchant/common/components/vat-review-section/vat-review-list-section.vue';
import {
  isReviewSubmitDisabled,
  normaliseOssData,
  normaliseOutboundWarehouseData,
  normaliseZfsVatData,
} from 'Merchant/general/components/company-compliance-details/util';
import { handleReviewSubmit } from 'Merchant/general/components/company-compliance-details/submitHandler';
import VatStatusIcon from 'Merchant/common/components/review-status-icon/review-status-icon.vue';
import { OssInfoT } from 'Api/models/Oss';
import { ZfsVatInfoT } from 'Api/models/ZfsVat';
import { OutboundWarehouseInfoT } from 'Api/models/OutboundWarehouse';
import NoDataSection from 'Merchant/general/components/company-compliance-details/no-data-compliance-section.vue';
import ReviewStatusLegend from 'Merchant/common/components/review-status-legend/review-status-legend.vue';
import {
  ApprovalStatusT,
  SelectableReviewStatusT,
} from 'Api/models/ApprovalStatus';
import { VatReviewListItemT } from 'Merchant/common/components/vat-review-section/types';

const FETCH_ERROR_MESSAGE = 'Failed to fetch compliance data for review';
const UPDATE_ERROR_MESSAGE =
  'Failed to update the review status for compliance data';

export default Vue.extend({
  components: {
    ReviewStatusLegend,
    NoDataSection,
    VatStatusIcon,
    VatReviewListSection,
    VatReviewSection,
    VatIdDetails,
    VatAddress,
  },

  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      showError: false,
      complianceStatus: null as ApprovalStatusT,
      errorMessage: '',
      requirements: [] as MerchantComplianceRequirementT[],
      euFixedEstablishmentData: null as FixedEstablishmentResponseT,
      ukFixedEstablishmentData: null as FixedEstablishmentResponseT,
      ossData: null as OssInfoT,
      zfsVatData: null as ZfsVatInfoT[],
      outboundWarehouseData: null as OutboundWarehouseInfoT[],
      selectedStatuses: {
        euFixedEstablishment: '' as ApprovalStatusT,
        ukFixedEstablishment: '' as ApprovalStatusT,
        oss: '' as ApprovalStatusT,
        outboundWarehouse: {},
        zfsVat: {},
      },
      providedRejectionReasons: {
        euFixedEstablishment: '',
        ukFixedEstablishment: '',
        oss: '',
        outboundWarehouse: {},
        zfsVat: {},
      },
      titles: {
        euFixedEstablishment: 'Fixed Establishment EU',
        ukFixedEstablishment: 'Fixed Establishment UK',
        oss: 'One Stop Shop (OSS)',
        outboundWarehouse: 'Outbound warehouse details',
        zfsVat: 'Zalando Fulfillment Solutions (ZFS)',
      },
      // @ts-ignore
      ...this.mapState({
        legalEntityId: 'flow.general.merchant.data.legalEntityId',
      }),
      // @ts-ignore
      ...this.mapState({
        merchantId: 'flow.general.merchant.data.$id',
      }),
      sectionTypes: MerchantComplianceRequirementT,
    };
  },

  computed: {
    zfsVatList(): VatReviewListItemT[] {
      return normaliseZfsVatData(this.zfsVatData, this.selectedStatuses.zfsVat);
    },
    outboundWarehouseList(): VatReviewListItemT[] {
      return normaliseOutboundWarehouseData(
        this.outboundWarehouseData,
        this.selectedStatuses.outboundWarehouse
      );
    },
    ossVatDetails() {
      return normaliseOssData(this.ossData);
    },
    isSubmitBtnDisabled(): boolean {
      if (this.isLoading) return true;
      return isReviewSubmitDisabled({
        requirements: this.requirements,
        selectedStatuses: this.selectedStatuses,
        providedRejectionReasons: this.providedRejectionReasons,
        outboundWarehouseData: this.outboundWarehouseData,
        zfsVatData: this.zfsVatData,
        ossData: this.ossData,
        euFixedEstablishmentData: this.euFixedEstablishmentData,
        ukFixedEstablishmentData: this.ukFixedEstablishmentData,
      });
    },
    showSubmitBtn(): boolean {
      return (
        this.isReviewEnabled &&
        this.complianceStatus === ApprovalStatusT.IN_REVIEW
      );
    },
    isEuFixedEstablishmentApproved(): boolean {
      return (
        this.euFixedEstablishmentData?.approvalStatus ===
        ApprovalStatusT.APPROVED
      );
    },
    isUkFixedEstablishmentApproved(): boolean {
      return (
        this.ukFixedEstablishmentData?.approvalStatus ===
        ApprovalStatusT.APPROVED
      );
    },
    isReviewEnabled(): boolean {
      return !this.readOnly;
    },
  },

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchRequirement();
      await this.fetchDataToReview();
      await this.fetchComplianceStatus();
    } catch (e) {
      this.showApiError(FETCH_ERROR_MESSAGE);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async fetchComplianceStatus(): Promise<void> {
      this.complianceStatus = (
        await fetchMerchantComplianceStatus(this.merchantId)
      ).status;
    },
    async fetchRequirement(): Promise<void> {
      this.requirements = await fetchMerchantComplianceDetailsRequirements(
        this.merchantId
      );
    },
    async fetchDataToReview(): Promise<void> {
      if (
        this.isSectionRequired(
          MerchantComplianceRequirementT.FIXED_ESTABLISHMENT_EU
        )
      ) {
        this.euFixedEstablishmentData = await fetchFixedEstablishmentData(
          this.legalEntityId,
          FixedEstablishmentTypeT.EU
        );
      }

      if (this.isSectionRequired(MerchantComplianceRequirementT.OSS)) {
        this.ossData = await fetchOssData(this.legalEntityId);
      }

      if (
        this.isSectionRequired(
          MerchantComplianceRequirementT.FIXED_ESTABLISHMENT_UK
        )
      ) {
        this.ukFixedEstablishmentData = await fetchFixedEstablishmentData(
          this.legalEntityId,
          FixedEstablishmentTypeT.UK
        );
      }

      if (this.isSectionRequired(MerchantComplianceRequirementT.ZFS_VAT)) {
        this.zfsVatData = await fetchZfsVatData(this.legalEntityId);
      }

      if (
        this.isSectionRequired(
          MerchantComplianceRequirementT.OUTBOUND_WAREHOUSES
        )
      ) {
        this.outboundWarehouseData = await fetchOutboundWarehouseData(
          this.merchantId
        );
      }
    },
    async handleSubmit(): Promise<void> {
      this.isSubmitting = true;

      try {
        await handleReviewSubmit({
          legalEntityId: this.legalEntityId,
          merchantId: this.merchantId,
          requirements: this.requirements,
          selectedStatuses: this.selectedStatuses,
          providedRejectionReasons: this.providedRejectionReasons,
          outboundWarehouseData: this.outboundWarehouseData,
          zfsVatData: this.zfsVatData,
          euFixedEstablishmentData: this.euFixedEstablishmentData,
          ukFixedEstablishmentData: this.ukFixedEstablishmentData,
          ossData: this.ossData,
        });
        window.location.reload();
      } catch (e) {
        this.showApiError(UPDATE_ERROR_MESSAGE);
      } finally {
        this.isSubmitting = false;
      }
    },
    isSectionRequired(section: MerchantComplianceRequirementT): boolean {
      return this.requirements.includes(section);
    },
    showApiError(message: string): void {
      this.showError = true;
      this.errorMessage = message;
    },
    onRejectionReasonChange(type: string, message: string, id?: string): void {
      if (id !== undefined) {
        this.$set(this.providedRejectionReasons[type], id, message);
      } else {
        this.providedRejectionReasons[type] = message;
      }
    },
    onReviewStatusChange(
      type: string,
      status: SelectableReviewStatusT,
      id?: string
    ): void {
      if (id !== undefined) {
        this.$set(this.selectedStatuses[type], id, status);
      } else {
        this.selectedStatuses[type] = status;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.v-banner {
  background-color: #ebeff5 !important;
  border: 1px solid #d8d8da !important;
  color: #283b8a !important;
  text-color: #283b8a !important;
}
</style>
