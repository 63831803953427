import { render, staticRenderFns } from "./vat-review-list-section.vue?vue&type=template&id=429d8e52&scoped=true&lang=pug"
import script from "./vat-review-list-section.vue?vue&type=script&lang=ts"
export * from "./vat-review-list-section.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "429d8e52",
  null
  
)

export default component.exports